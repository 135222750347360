"use client"  /* TODO: figure out what can be server-side */

import { useRouter, useSearchParams } from "next/navigation"
import useSWR from "swr";
import React, { useState, useEffect } from "react";
import Link from 'next/link';

// Using proxying for now.  (TODO: should this go in next.config.js too?)
export const API_BASE_URL = "/api";
export const WS_API_BASE_URL = "/wsapi";
// export const API_BASE_URL = "http://cyndi:3001";

// Shared fetcher with error handling
export const urlFetcher = (url: string) => fetch(url).then(res => {
    if (!res.ok) throw Error(res.statusText);
    return res.json();
});

export function useAuthInfo() {
  const { data, error, isLoading } = useSWR(API_BASE_URL + "/auth/userinfo", urlFetcher);
  return { authInfo: data, authInfoError: error, authInfoIsLoading: isLoading };
}

/// A common struct returned from the backend, representing a concept reference
/// with display information.
export interface DisplayConceptRef {
  cref: string;
  display: string;
}

export function cref_browse_url(cref: string): string {
    return `/community/${cref}`;
}

export function Header() {
    const { data: userInfoData, error, isLoading } = useSWR(API_BASE_URL + "/auth/userinfo", urlFetcher);

    return ( userInfoData ?
    <header className="page-header">
        <div className="title"><h1>F117</h1></div>
        <nav className="buttonbar">
            <ul className="buttonlist">
            <li><Link href="/">Home</Link></li>
            <li><Link href="/citizens">Citizens</Link></li>
            <li><Link href="/help">Help</Link></li>
            <li><Link href="/account">👤 {userInfoData.username}</Link></li>
            </ul>
        </nav>
    </header>
    :
    <header className="page-header">
        <div className="title"><h1>F117</h1></div>
        <nav className="buttonbar">
            <ul className="buttonlist">
                <li><Link href="/login">Login</Link></li>
                <li><Link href="/register">Register</Link></li>
            </ul>
        </nav>
    </header>
    );
}

export function Footer() {
    return (
        <div className="page-footer">
            © 2024 F117
        </div>
    );
}

export function ContentCard({title, children, collapsible, initialState = true}:
    {title?: string, children: React.ReactNode, collapsible: boolean, initialState?: boolean}) {
  const [isOpen, setIsOpen] = useState(initialState);

  let content: React.ReactNode;
  if (collapsible) {
    if (React.Children.count(children) !== 2) {
      console.error("Collapsible ContentCard expects exactly 2 children, but got ", React.Children.count(children));
      return null;
    }
    let [summary, full] = React.Children.toArray(children);
    content = isOpen ? full : summary;
  } else {
    content = children;
  }

  return (
    <div className="content">
      {title && <h3 className="content-title">{title}</h3>}
      <div className="content-wrapper">
        <div className={`collapsible__content ${isOpen ? 'collapsible__content--open' : ''}`}>
          {content}
        </div>
        {collapsible && (
          <div className="toggle-wrapper">
            <button 
              className="collapsible__toggle"
              onClick={() => setIsOpen(!isOpen)}
            >
              <i className={`chevron ${isOpen ? "chevron-up" : "chevron-down"}`}></i>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

// We're not using this right now, and it can't be properly
// typed without defining the RefD data structure, which is nontrivial.
//
// function refd_to_str(refd: object): string {
//   if (!refd) return "";
//   let result = refd?.genus + " : ";
//   for (let key in refd?.constraints) {
//     result += `${key}=${refd.constraints[key]} `;
//   }
//   return result;
// }

export function strip_en(s: string): string {
    return s.replace("@en:", "");
}

export function ToggleContainer({title, children, onClose}:
        {title?: string, children: React.ReactNode, onClose: () => void}) {
    return (
        <div className="toggle-container">
            <div className="toggle-container-content">
                {title && <h2>{title}</h2>}
                {children}
            </div>
        </div>
    );
}

import ReactMarkdown from 'react-markdown';
import { SelectItem, TopicSelector } from './topic_selector';

const containsMarkdown = (text: string): boolean => {
    // Regular expression to match common Markdown syntax
    const markdownRegex = /(\*\*|__|\*|_|`|#|\[.*\]\(.*\)|!\[.*\]\(.*\)|\n\s*[-*+]\s|\n\s*\d+\.\s|\n\s*>\s)/;
    return markdownRegex.test(text);
};

export function ComposeBox({ onClose, topic, citationKey}: { onClose: () => void, topic: string, citationKey?: string | null }) {
    const [content, setContent] = useState('');
    const [isPosting, setIsPosting] = useState(false);
    const router = useRouter();

    const handlePost = async () => {
        setIsPosting(true);
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ content, topic }),
            };

            if (citationKey) {
                requestOptions.body = JSON.stringify({ content, topic, citation: citationKey });
            }

            const response = await fetch(`${API_BASE_URL}/doc/new`, requestOptions);
            if (!response.ok) {
                throw new Error('Failed to post content');
            }

            const responseData = await response.json();
            console.log('New doc key:', responseData.doc_key);

            // Handle successful post: redirect to the new doc page
            router.push(`/doc/${responseData.doc_key}`);
        } catch (error) {
            console.error('Error posting content:', error);
            // Handle error (e.g., show an error message to the user)
        } finally {
            setIsPosting(false);
        }
    };

    const showPreview = containsMarkdown(content);

    return (
        <ToggleContainer onClose={onClose}>
            <div className="compose-box">
                <textarea
                    rows={5}
                    className="full-width"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    placeholder="Post a question, comment, answer, etc..."
                />
                {showPreview && (
                    <div className="preview">
                        <h3>Preview:</h3>
                        <ReactMarkdown>{content}</ReactMarkdown>
                    </div>
                )}
            </div>
            <div className="right-align">
                <button 
                    className="controls" 
                    onClick={handlePost}
                    disabled={isPosting}
                >
                    {isPosting ? 'Posting...' : 'Post'}
                </button>
                <button className="controls" onClick={onClose}>
                    Discard
                </button>
            </div>
        </ToggleContainer>
    );
}

// TODO: this enum and useGet*() functions are copypasted all over.  Consolidate
// and generalize.

enum LoadStatus {
  NoArg,
  Error,
  IsLoading,
  EmptyResponse,
  Success,
}

export function showExploreLinks(cdef: Record<string, any>): boolean {
  return cdef.sup?.length > 0 || cdef.sub?.length > 0;
}
export function ExploreLinks({cdef}: {cdef: Record<string, any>}) {
    return (
        <div className="content explore-links">
            <div className="explore-section">
                {cdef.sup?.length > 0 && <h3>Broader:</h3>}
                <ul className="no-bullets">
                    {cdef.sup?.map((sup: string) => (
                        <li key={sup}>
                            <Link href={cref_browse_url(sup)}>
                                {strip_en(sup)}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="explore-section">
                {cdef.sub?.length > 0 && <h3>Subcategories:</h3>}
                <ul className="no-bullets">
                    {cdef.sub?.map((sub: string) => (
                        <li key={sub}>
                            <Link href={cref_browse_url(sub)}>
                                {strip_en(sub)}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export function UserPosts({ posts }: { posts: any[] }) {
  const [topicDisplayNames, setTopicDisplayNames] = useState<Record<string, string>>({});

  /// TODO: if we fix the APIs so when docs are returned, embedded conceptrefs are 
  /// translated into DisplayConceptRef, we wouldn't need htis.
  useEffect(() => {
    const fetchTopicDisplayNames = async () => {
      if (!posts || posts.length === 0) return;

      const uniqueTopics = Array.from(
        new Set(posts.map(post => post.topic)
            .filter(topic => topic != null)));

      try {
        const response = await fetch(`${API_BASE_URL}/schema/displayinfo`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(uniqueTopics),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch topic display names');
        }

        const displayNames = await response.json();
        const topicMap: Record<string, string> = {};
        uniqueTopics.forEach((topic, index) => {
          topicMap[topic] = displayNames[index] || topic;
        });

        setTopicDisplayNames(topicMap);
      } catch (error) {
        console.error('Error fetching topic display names:', error);
      }
    };

    fetchTopicDisplayNames();
  }, [posts]);

  if (!posts || posts.length === 0) return (<div>No posts found</div>);

  return (
    <ul className="no-bullets">
      {posts.map((post: any, index: number) => (
        <li key={index}>
          <Link href={`/doc/${encodeURIComponent(post._key)}`}>
            {(new Date(post.provenance.createdon)).toLocaleDateString()}
          </Link>: 
          topic: 
          <Link href={`/community/${encodeURIComponent(post.topic)}`}>
            {topicDisplayNames[post.topic] || post.topic}
          </Link>
          <br/>{post.text}
        </li>
      ))}
    </ul>
  );
}

export function FollowingList({ following }: { following: Follow[] }) {
  return (
    <ul>
      {following.map((follow, index) => (
        <li key={index}>
          <Link href={`/citizen/${encodeURIComponent(follow.username)}`}>
            {follow.username}

            {follow.topics.length > 0 && (
            /* TODO: make this pretty */
            <div>Topics: {follow.topics.map((c: DisplayConceptRef) => c.display).join(', ')} </div> ) }

          </Link>
        </li>
      ))}
    </ul>
  );
}


//////////////////////////////////////////////////////////////////////////////
//
// Functions for accessing and updating info about the current logged-in user
// from the backend
// 

// Contains communities and follows.
export interface Follow {
    username: string;
    topics: DisplayConceptRef[];
}
export interface UserDetails {
  // We omit the username because auth is the canonical source for that
    communities: DisplayConceptRef[];
    following: Follow[];
}

export const EmptyUserDetails: UserDetails = {
    communities: [],
    following: [],
};

export function followsMap(follows: Follow[] | null): Map<string, DisplayConceptRef[]> {
    let followsMap = new Map<string, DisplayConceptRef[]>();
    if (follows != null) {
      follows.forEach((follow) => {
          followsMap.set(follow.username, follow.topics);
      });
    }
    return followsMap;
};

// use useSWR here?
export async function getUserDetails(
  username: string
) : Promise<UserDetails> {
    // TODO can this be fetchURL?
    let resp = await fetch(`${API_BASE_URL}/user/get_details/${encodeURIComponent(username)}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    });
    if (!resp.ok) {
        throw new Error("Error getting user details: " + resp.statusText);
    }

    let json_data = await resp.json();
    return json_data;
}

export async function addUserDetailsCommunity(username: string, topic: string): Promise<UserDetails> {
  return updateUserCommunities(username, { add_communities: [topic] } );
}

export async function remUserDetailsCommunity(username: string, topic: string): Promise<UserDetails> {
  return updateUserCommunities(username, { rem_communities: [topic] } );
}

// TODO: should the caller pass in all this info, or just the username, and let
// the component do the loading?  Or, should we pass in a structured follow object?
interface FollowButtonProps {
  authUsername: string;
  followUsername: string;
  currentlyFollowing: boolean;
  topics: DisplayConceptRef[];
  onFollowUpdate?: (updatedUserDetails: UserDetails) => void;
}

export function FollowButton({ authUsername, followUsername, currentlyFollowing, topics, onFollowUpdate }: FollowButtonProps) {
  // Need to init selectedTopics from a backend call

  const [showModal, setShowModal] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState<DisplayConceptRef[]>(topics);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTopics([]);
  };

  const handleFollowUnfollow = async () => {
    try {
      let request: UpdateUserFollowsRequest = {
        username: authUsername,
        follow: followUsername,
        action: currentlyFollowing ? "Unfollow" : "Follow",
        topics: currentlyFollowing ? [] : selectedTopics.map(topic => topic.cref),
      };
        
      let updatedUserDetails: UserDetails = await updateUserFollows(authUsername, request);
      if (onFollowUpdate) {
        onFollowUpdate(updatedUserDetails);
      }
      handleCloseModal();
    } catch (error) {
      console.error('Error following/unfollowing user:', error);
    }
  };

  const updateFollowTopics = async () => {
    try {
      let request: UpdateUserFollowsRequest = {
        username: authUsername,
        follow: followUsername,
        action: "Update",
        topics: selectedTopics.map(topic => topic.cref),
      };
        
      let updatedUserDetails: UserDetails = await updateUserFollows(authUsername, request);
      if (onFollowUpdate) {
        onFollowUpdate(updatedUserDetails);
      }
      handleCloseModal();
    } catch (error) {
      console.error('Error updating follow of user:', error);
    }
  }

  const handleTopicSelect = (selectItem: SelectItem) => {
    let topic: DisplayConceptRef = {
      cref: selectItem.value,
      display: selectItem.label,
    };
    setSelectedTopics(prevTopics => [...prevTopics, topic]);
  };

  return (
    <>
      <button onClick={handleOpenModal} className="follow-button">
        {currentlyFollowing ? `Following` : `Follow`}
      </button>
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-content">

              <h2>{currentlyFollowing ? 'Unfollow' : 'Follow'}
                {' '}{followUsername}
                {' '}{currentlyFollowing ? 'or update topics:' : 'on:' }
              </h2>

              {selectedTopics.length == 0
              ? <ul>
                <li><i>All topics</i></li>
              </ul>
              : (
                <div className="selected-topics">
                  <ul>
                    {selectedTopics.map((topic, index) => (
                      <li key={index} className="selected-topic-item">
                        <span>{topic.display}</span>
                        <button 
                          className="remove-topic-button" 
                          onClick={() => setSelectedTopics(prevTopics => prevTopics.filter((_, i) => i !== index))}
                        >
                          ✕
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div>
                {selectedTopics.length == 0
                  ? 'Or select topics:'
                  : 'Add topic:'}
                <TopicSelector onChangeFn={(topic: SelectItem) => handleTopicSelect(topic)} />
              </div>

              <div className="modal-buttons">
                { currentlyFollowing && (
                  <button onClick={updateFollowTopics} className="confirm-button">
                    Update Topics
                  </button>
                ) }
                <button onClick={handleFollowUnfollow} className="confirm-button">
                  {currentlyFollowing ? 'Unfollow' : 'Follow'}
                </button>
                <button onClick={handleCloseModal} className="cancel-button">
                  Cancel
                </button>
              </div>

            </div>
          </div>
        </div>
      )}
    </>
  );
}

interface JoinButtonProps {
  authUsername: string;
  community: DisplayConceptRef;
  currentlyJoined: boolean;
  onJoinUpdate?: (updatedUserDetails: UserDetails) => void;
}

export function JoinButton({ authUsername, community, currentlyJoined, onJoinUpdate }: JoinButtonProps) {
  const handleJoin = async () => {
    try {
      let updatedUserDetails: UserDetails = currentlyJoined ?
        await remUserDetailsCommunity(authUsername, community.cref) :
        await addUserDetailsCommunity(authUsername, community.cref);
      if (onJoinUpdate) {
        onJoinUpdate(updatedUserDetails);
      }
    } catch (error) {
      console.error('Error joining community:', error);
    }
  };

  return (
    <button onClick={handleJoin} className="join-button">
      {currentlyJoined ? `Leave` : `Join`}
    </button>
  );
}

// TODO: move these into a separate APIs file.  Then eventually find a way
// to keep them in sync with the backend.
interface UpdateUserCommunitiesRequest {
    username: string;
    add_communities?: string[];
    rem_communities?: string[];
}
async function updateUserCommunities(
  username: string,
  req_fields: any,
) : Promise<UserDetails> {
  let request: UpdateUserCommunitiesRequest = {
    username: username,
    ...req_fields,
  };

  console.log("updateUserCommunities: request: ", request);

  let resp = await fetch(API_BASE_URL + "/user/set_communities", {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  });
  if (!resp.ok) {
    throw new Error("Error modifying community: " + resp.statusText);
  }

  let json_data = await resp.json();
  return json_data;
}

// TODO: move these into a separate APIs file.  Then eventually find a way
// to keep them in sync with the backend.
interface UpdateUserFollowsRequest {
    username: string;
    follow: string;
    action: "Follow" | "Unfollow" | "Update";
    topics?: string[];
}
// TODO: lots of code in common with the above
async function updateUserFollows(
  username: string,
  req_fields: any,
) : Promise<UserDetails> {
  let request: UpdateUserCommunitiesRequest = {
    username: username,
    ...req_fields,
  };

  console.log("updateUserFollows: request: ", request);

  let resp = await fetch(API_BASE_URL + "/user/set_follows", {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  });
  if (!resp.ok) {
    throw new Error("Error modifying follows: " + resp.statusText);
  }

  let json_data = await resp.json();
  return json_data;
}

