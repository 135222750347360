'use client'
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import Select, { ActionMeta, SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import { API_BASE_URL, DisplayConceptRef } from './common';
import { useRouter } from 'next/navigation';
import { Display } from 'next/dist/compiled/@next/font';

// Represents one option in the AsyncSelect dropdown.
// TODO: these are essentially DisplayConceptRefs, and we do a lot of translating
// to/from that.  Can we just use DisplayConceptRef directly?
export interface SelectItem {
  value: string;
  label: string;
}
function parseResponse(data: DisplayConceptRef[]): SelectItem[] {
  return data.map(item => {
    return {
      value: item.cref,
      label: item.display,
    };
  });
}

const promiseOptions = (inputValue: string) => {
  if (inputValue === "") return Promise.resolve([]);
  return fetch(API_BASE_URL + `/schema/prefix_search/${inputValue}`)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data: DisplayConceptRef[]) => {
      return parseResponse(data);
    })
    .catch(error => {
      console.error('Error fetching data: ', error);
      return [];
    });
};

// By default, TopicSelector will on selection navigate to the topic page.
// You can override this by passing in a different onChange function.
// TODO: figure out how to type this properly
type TopicSelectorProps = {
  onChangeFn?: (value: any) => void;
}
export const TopicSelector: React.FC<TopicSelectorProps>
    = ({ onChangeFn }) => {
  const router = useRouter();

  const inputRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === '/') {
        event.preventDefault();
        inputRef.current && (inputRef.current as any).focus();
      }
    };

    window.addEventListener('keypress', handleKeyDown);

    return () => {
      window.removeEventListener('keypress', handleKeyDown);
    };
  }, []);

  if (onChangeFn === undefined) {
    onChangeFn = (selectedOption: SelectItem | null) => {
      if (selectedOption === null) {
        console.log("TopicSelectorNew: null topic");
        return;
      }
      router.push(`/community/${selectedOption.value}`);
    }
  }

  return (
    <AsyncSelect
      cacheOptions   // TODO: figure out how to invalidate cache?
      loadOptions={promiseOptions}
      defaultOptions
      placeholder="Search..."
      onChange={onChangeFn}
      className="topic-selector"
      classNamePrefix="react-select"
      ref={inputRef}
    />
  );
};